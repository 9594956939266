/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

html, body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
}

#neuroglancer-container {
  width: 100vw;
  overflow: hidden;
  flex: 1;
}

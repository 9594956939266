
.dropdownList[data-v-31006175] {
  position: relative;
  font-size: 10pt;
}
.ng-extend .dropdownList > button[data-v-31006175] {
  width: 100%;
  height: 100%;
  padding: 0 4px;
}
.dropdownMenu[data-v-31006175] {
  min-width: 100%;
  position: absolute;
  right: 0;
  top: 33px;
  background-color: var(--color-dark-bg);
  border-radius: 8px;
  border: 1px solid var(--color-light-bg);
  overflow: hidden;
  z-index: 10;
}



.dropdownList:last-child .dropdownMenu {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
#extensionBar button {
  font-size: 10pt;
}
#insertNGTopBar > div {
  width: 100%;
}
#extensionBar {
  display: flex;
  height: 40px;
  align-items: center;
  background-color: var(--color-dark-bg);
  z-index: 30;
}
#extensionBar > * {
  height: 100%;
  display: flex;
  align-items: center;
}
#loginsDropdown li.none {
  opacity: 0.5;
  padding: 0 10px;
}
#loginsDropdown li > div:last-child {
  border-bottom: none;
}
#loginsDropdown li > div {
  display: grid;
  grid-template-columns: auto min-content;
  border-bottom: 1px solid #4a4a4a;
}
#loginsDropdown .loginData {
  display: grid;
  white-space: nowrap;
  padding: 10px;
}
#loginsDropdown .logoutButton {
  display: grid;
  align-content: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0;
}
#loginsDropdown .loginRow:hover .logoutButton {
 opacity: 0.25;
}
#loginsDropdown .loginRow:hover .logoutButton:hover {
  opacity: 1;
  background-color: #db4437;
  cursor: pointer;
}
#loginsDropdown li.header {
  padding: 5px;
  background-color: #ffffff1c;
}
#loginsDropdown .loginData.expired {
  opacity: 0.5;
}
.ng-extend-logo > a, .ng-extend-logo > a > img {
  height: 100%;
}

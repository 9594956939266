
.header[data-v-43323f97] {
  font-size: 16px;
}
.selectedVolume .confirmSelection[data-v-43323f97] {
  justify-self: end;
}
.back[data-v-43323f97] {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
  cursor: pointer;
}
.exit[data-v-43323f97] {
  position: absolute;
  right: 5px;
  width: 22px;
}
.ng-extend .volumeSelector button[data-v-43323f97]:not(.fill) {
  opacity: 0.75;
}
.ng-extend .volumeSelector button[data-v-43323f97]:not(.fill):hover {
  background-color: initial;
  opacity: 1;
}
.content[data-v-43323f97] {
  max-width: 60vw;
  display: grid;
  gap: 10px;
}
.darkModal[data-v-43323f97] {
  /* background-color: --color-medium-bg; */
  padding: 50px;
  border-radius: 10px;
}
.volumes[data-v-43323f97] {
  display: grid;
  grid-gap: 5px;
  max-height: 75vh;
  overflow-y: auto;
}
.volumes > li > div[data-v-43323f97] {
  border: 1px solid hsl(0 0% 25% / 1);
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  display: grid;
  gap: 5px;
}
.volumes > li > div[data-v-43323f97]:hover:not(.active) {
  background-color:  hsl(0 0% 20% / 1);
}
.volumes .layers[data-v-43323f97], .description[data-v-43323f97] {
  font-size: 14px;
  opacity: 0.5;
}
.layerSplit[data-v-43323f97] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}
.volumeSelector .header[data-v-43323f97] {
  display: grid;
  grid-template-columns: min-content auto;
}
.volumeSelector > .header > div[data-v-43323f97] {
  grid-row: 1;
}
.header .title[data-v-43323f97] {
  /* text-align: center; */
  display: grid;
  grid-row: 1;
  grid-column: 1/3;
  justify-content: center;
  align-content: center;
}
.volume .layers[data-v-43323f97] {
  display: grid;
  grid-gap: 5px;
  grid-auto-rows: min-content;
}
.volume .layers > div[data-v-43323f97] {
  padding: 5px;
  border: 1px solid hsl(0 0% 25% / 1);
  border-radius: 5px;
  cursor: pointer;
}
.volume .layers > div[data-v-43323f97]:hover {
  background-color:  hsl(0 0% 20% / 1);
}
.volume .layers > div[data-v-43323f97]:first-child {
  border: none;
  cursor: inherit;
  background: none;
}
.volume .layers > div.selected[data-v-43323f97] {
  border: 1px solid hsl(0 0% 100% / 1);
}
.volume .layers > div:not(.active).selected[data-v-43323f97] {
  background-color:  hsl(0 0% 20% / 1);
}
.volumeSelector div.active[data-v-43323f97] {
  background: var(--color-ng-selected-faint);
}
.volumeSelector div.active[data-v-43323f97]:hover {
  background: var(--color-ng-selected-less-faint);
}
.volumeSelector div.active[data-v-43323f97]:not(.selected) {
  border: 1px solid var(--color-ng-selected);
}
.volume .description[data-v-43323f97] {
  max-width: 400px;
}
.volume .layers[data-v-43323f97] {
  opacity: 1;
}

